<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-toolbar-title>Areas and Assignments</v-toolbar-title>
    <br>
    <v-row no-gutters>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFranchise"
          :items="franchises"
          :item-text="getFullFranchiseName"
          item-value="Name"
          label="Select Location"
          chips
          multiple
          v-on:change="filterLocation"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="ml-2  mt-1"
              @click="clearSearch()"
              color="info"
              v-bind="attrs"
              v-on="on"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <span>Clear</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <progress-spinner :isLoading="loading" />
    <v-data-table
      :key="dynamicIndex"
      :headers="headers"
      :items="areas"
      sort-by="Name"
      class="elevation-1"
      @click:row="handleClickRow"
    >
      <template v-slot:top>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.Name"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-select
                      v-model="selectedFranchise"
                      :items="franchises"
                      label="Select franchise"
                      :item-text="getFranchiseNameCode"
                      item-value="Name"
                      persistent-hint
                      return-object
                      v-on:change="filterEmployees"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-select
                      v-model="employeesToAssignArea"
                      :items="filteredEmployees"
                      label="Choose employees to assign"
                      :item-text="getFullName"
                      item-value="Id"
                      multiple
                      chips
                      hint=""
                      persistent-hint
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="assignEmployeesToArea"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAreaEmployee" max-width="800px">
          <v-card>
            <v-card-title class="text-h5"
              >Employees Assigned to an Area</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.Name"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-data-table
                      :headers="employeeHeaders"
                      :items="employeesByArea"
                      :hide-default-footer="true"
                      sort-by="FirstName"
                      class="elevation-1"
                    >
                      <template v-slot:item.Name="{ item }"
                        >{{ item.FirstName }} {{ item.LastName }}</template
                      >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          medium
                          class="mr-2"
                          @click="removeEmployee(item)"
                          color="primary"
                        >
                          mdi-minus-circle
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <progress-spinner :isLoading="loading" />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAreaEmployees"
                >Cancel</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          :retain-focus="false"
          v-model="dialogRemove"
          max-width="300px"
        >
          <v-card>
            <v-card-title class="text-h5"
              >Do you want to remove this employee?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="removeEmployeeConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.assignedTo="{item}">
        <v-chip class="ma-1" v-for="(assignedItem, index) in item.assignedTo" :key="index">
          {{assignedItem}}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon medium class="mr-2" @click="editItem(item)" color="primary">
          mdi-plus-circle
        </v-icon>
        <v-icon medium @click="viewAreaEmployees(item)" color="primary">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
const axios = require("axios");
export default {
  data: () => ({
    dynamicIndex: 0,
    dialog: false,
    dialogRemove: false,
    dialogAreaEmployee: false,
    employees: [],
    filteredEmployees:[],
    employeesByArea: [],
    employeesToAssignArea: [],
    loading: true,
    franchises: [],
    selectedFranchise: null,
    emp: {
      employeeIds: [],
    },
    headers: [
      {
        text: "Area Name",
        align: "start",
        sortable: true,
        value: "Name",
      },
      {
        text: "Location",
        align: "start",
        sortable: true,
        value: "Franchise.Name",
      },
      {
        text: "Assigned To",
        align: "start",
        sortable: false,
        value: "assignedTo",
      },
      // { text: "Actions", align: "center", value: "actions", sortable: false },
    ],
    employeeHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "Name",
      },
      { text: "WorkEmail", value: "WorkEmail" },
      { text: "FranchiseName", value: "FranchiseName" },
      { text: "Remove", value: "actions", sortable: false },
    ],
    areas: [],
    areasData: [],
    desserts: [],
    editedIndex: -1,
    editedItem: {},
    removeIndex: -1,
    removeItem: {},
    removeDefaultItem: {},
    defaultItem: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Remove Employee" : "Assign Employee";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogRemove(val) {
      val || this.closeDelete();
    },
    dialogAreaEmployee(val) {
      val || this.closeAreaEmployees();
    },
  },

  created() {},

  methods: {
    handleClickRow(item) {
      this.$router.push({ name: 'Area Details', params: item });
    },
    clearSearch() {
      this.selectedFranchise = null;
      this.getAreas();
    },
    filterLocation() {
      if (this.selectedFranchise.length === 0) {
        this.areas = this.areasData;
        return;
      }
      if (!this.areasData || this.areasData.length === 0) {
        this.areasData = [...this.areas];
      }
      let filtered = this.areasData.filter((filterItem) => {
        return this.selectedFranchise.includes(filterItem.Franchise.Name);
      })
      this.areas = filtered;
    },
    getFullFranchiseName(item) {
      return `${item.Name} ${item.Code}`;
    },
    async getAreas() {
      this.loading = true;
      this.areas = [];
      try {
        let { data } = await axios.get(`auth/current-user/authorized-areas`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          }
        });

        this.areas = data;
        this.areas.map(async (item, index) => {
          let assignedEmployees = await this.getEmployeesByArea(item.Id, true);
          let employeeNames = assignedEmployees.map((item) => { return `${item.FirstName} ${item.LastName} - ${item.PriorityStatus}` });
          this.areas[index].assignedTo = employeeNames;
          this.dynamicIndex += 1;
        });

      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getFranchises() {
      this.loading = true;
      this.priorities = [];
      try {
        let { data } = await axios.get(`auth/current-user/authorized-franchises`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.franchises = data;
        this.franchises = this.franchises.sort((a, b) => {
          const nameA = a.Name.toLowerCase();
          const nameB = b.Name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getEmployees() {
      this.loading = true;
      this.employees = [];
      try {
        let { data } = await axios.get(`employees?page=1&size=500`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.employees = data.rows.sort(function (a, b) {
          if (a.FirstName < b.FirstName) {
            return -1;
          }
          if (a.FirstName > b.FirstName) {
            return 1;
          }
          return 0;
        });
        this.filteredEmployees=this.employees
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    filterEmployees() {
      let filtered = null;
      filtered = this.employees.filter(
        (item) => item.Franchise.Id == this.selectedFranchise.Id
      );

      this.filteredEmployees = filtered;
    },

    async getEmployeesByArea(areaId = null, returnData = false) {
      this.employeesByArea = [];
      try {
        this.loading = true;
        let { data } = await axios.get(
          `areas/${areaId ?? this.editedItem.Id}/assigned-employees`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (returnData) {
          return data;
        }
        this.employeesByArea = data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async assignEmployeesToArea() {
      try {
        this.loading = true;
        this.emp["employeeIds"] = this.employeesToAssignArea;
        console.log(this.emp);
        await axios.post(
          `areas/${this.editedItem.Id}/assign-employees`,
          this.emp,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            params: {
              id: this.editedItem.Id,
            },
          }
        );
        this.employeesToAssignArea = [];
        this.$toast.success("Employees assigned to an area successfully");
        this.selectedFranchise=null;
        this.filteredEmployees=this.employees;
        this.close();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async removeEmployee(item) {
      this.removeIndex = this.areas.indexOf(item);
      this.removeItem = Object.assign({}, item);
      this.dialogRemove = true;
    },

    viewAreaEmployees(item) {
      this.editedIndex = this.areas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogAreaEmployee = true;
      this.getEmployeesByArea();
    },

    editItem(item) {
      this.editedIndex = this.areas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async removeEmployeeConfirm() {
      try {
        this.loading = true;
        this.emp["employeeIds"].push(this.removeItem.Id);
        await axios.post(
          `areas/${this.editedItem.Id}/remove-employees`,
          this.emp,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.$toast.success("Employee removed from an area sucessfully");
        this.getEmployeesByArea();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
      this.closeDelete();
    },


    close() {
      this.dialog = false;
      this.selectedFranchise=null;
      this.filteredEmployees=this.employees;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogRemove = false;
      this.$nextTick(() => {
        this.removeItem = Object.assign({}, this.removeDefaultItem);
        this.removeIndex = -1;
      });
      this.emp["employeeIds"] = []
    },

    closeAreaEmployees() {
      this.dialogAreaEmployee = false;
      this.$nextTick(() => {
        this.removeItem = Object.assign({}, this.removeDefaultItem);
        this.removeIndex = -1;
      });
    },

    getFullName(item) {
      let fullName = `${item.FirstName} ${item.LastName}`;
      return fullName;
    },
    getFranchiseNameCode(item) {
      let nameCode = `${item.Name} - ${item.Code}`;
      return nameCode;
    },
  },
  mounted() {
    this.getAreas();
    this.getFranchises();
    this.getEmployees();
  },
};
</script>
<style>
tbody tr :hover {
  cursor: pointer;
}
</style>
